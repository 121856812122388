import "./App.css";

import React from "react";

import RoutesApp from "./Routes";

function App() {

  document.title = "Pollaris";

  return (
    <div>
      <RoutesApp />
    </div>
  );
}

export default App;