import { useParams } from "react-router-dom";
import PollarisResumeApi from "../Services/PollarisResumeApi";
import { useEffect } from "react";
import PollarisResumeService from "../Services/PollarisResumeService";


const PagAuth = () => {

  let loaded = false;

  const { bearerToken } = useParams("bearerToken");

  useEffect(() => {
    if (loaded) return;

    loaded = true;

    validarToken();

  }, [bearerToken]);

  const validarToken = async () => {

    const api = new PollarisResumeApi(true);

    api.setAuthorization(bearerToken);

    api.get("sistemascontratados")
      .then((response) => {

        new PollarisResumeService().setSistemasContratados(response);

        window.location.href = "/";

      })
      .catch((error) => {

        console.log(error);

        window.location.href = "/login";

      });
  }

  return (
    <div className="p-4 text-center">
      <h4>validando acesso...</h4>
      <div className="spinner-border text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )

}

export default PagAuth;