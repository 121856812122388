import PollarisResumeService from "./PollarisResumeService";

export default class PollarisResumeApi {
    constructor(login = false) {

        const service = new PollarisResumeService();

        if (window.location.href.indexOf("pollaris") > -1)
            this.url = "https://api.resume.pollaris.com.br/";
        else
            this.url = "https://api.resume.pollaris.com.br/";//this.url = "http://localhost:5083/";

        if (!login) {

            this.usuarioLogado = service.getUsuarioLogado();

            if (!this.usuarioLogado) {

                throw new Error("Não autenticado");

            } else {

                this.authorization = `${this.usuarioLogado.token_type} ${this.usuarioLogado.access_token}`;
            }
        }
    }

    setAuthorization(access_token) {

        const accessData = JSON.parse(atob(access_token.split(".")[1]));

        console.log(accessData);

        const authAutoData = {
            access_token: access_token,
            base64Auth: null,
            customer: JSON.parse(accessData["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]),
            expire: new Date(accessData.exp * 1000).toISOString(),
            expires_in: accessData.exp,
            keep: false,
            token_type: "Bearer"
        }

        new PollarisResumeService().setUsuarioLogado(authAutoData);

        this.authorization = `Bearer ${access_token}`;
    }

    refreshToken() {

        this.authenticate(this.usuarioLogado.base64Auth);
    }

    authenticate(base64Auth) {

        this.authorization = `Basic ${base64Auth}`;

        return this.request("POST", this.url + "authenticate");
    }

    get(path, data) {
        return this.request("GET", this.url + path, data);
    }

    post(path, data) {
        return this.request("POST", this.url + path, data);
    }

    put(path, data) {
        return this.request("PUT", this.url + path, data);
    }

    delete(path, data) {
        return this.request("DELETE", this.url + path, data);
    }

    request(method, url, data) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Authorization", this.authorization);
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    if (xhr.response) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        resolve(null);
                    }
                } else {

                    try {
                        const response = JSON.parse(xhr.response);
                        reject(response);
                    } catch (error) {

                        reject(xhr.status);
                    }

                }
            };
            xhr.onerror = () => {
                reject(JSON.parse(xhr.response));
            };
            xhr.send(JSON.stringify(data));
        });
    }
}