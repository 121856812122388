export default class PollarisResumeService {

    getSistemaSelecionado() {

        var sistemaSelecionado = localStorage.getItem("sistemaSelecionado");

        if (!sistemaSelecionado) {

            var sistemasContratados = this.getSistemasContratados();

            localStorage.setItem("sistemaSelecionado", sistemasContratados[0]);
        }

        return localStorage.getItem("sistemaSelecionado").toLowerCase();
    }

    setSistemaSelecionado(sistema) {

        localStorage.setItem("sistemaSelecionado", sistema);

        return this.getSistemaSelecionado();

    }

    getSistemasContratados() {

        return JSON.parse(localStorage.getItem("sistemascontratados"));
    }

    getUsuarioLogado() {

        return JSON.parse(localStorage.getItem("authentication"));
    }

    setUsuarioLogado(usuario) {
        localStorage.setItem("authentication", JSON.stringify(usuario));
    }

    setSistemasContratados(sistemas) {
        localStorage.setItem("sistemascontratados", JSON.stringify(sistemas));
    }
}