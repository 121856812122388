import { useState, useEffect } from "react";

import moment from "moment/moment";

import Utils from "../../Services/Utils";

import PollarisResumeApi from "../../Services/PollarisResumeApi";

import MounthSelect from "../Screen/MouthSelect";
import PollarisResumeService from "../../Services/PollarisResumeService";

const TableBoletos = (props) => {

    const api = new PollarisResumeApi();

    const service = new PollarisResumeService()

    const sistemaSelecionado = service.getSistemaSelecionado();

    const [dataInicio, setDataInicio] = useState(moment().format(`YYYY-MM-01`));

    const [dataFim, setDataFim] = useState(moment().format(`YYYY-MM-DD`));

    const [loaded, setLoaded] = useState(false);

    const [boletos, setBoletos] = useState([]);

    useEffect(() => {

        if (loaded) return;

        setLoaded(true);

        loadBoletos(dataInicio, dataFim);

    }, [])

    const loadBoletos = async (dataInicio, dataFim) => {

        api.get(`movimentacao/${sistemaSelecionado}/boletos/${dataInicio}/${dataFim}`).then(data => {

            setBoletos(data);
        });

    }

    const changeMes = (mes, ano) => {

        const dataInicio = `${ano}-${mes}-01`;

        const dataFim = `${ano}-${mes}-${moment(dataInicio).daysInMonth()}`;

        setDataInicio(dataInicio);

        setDataFim(dataFim);

        loadBoletos(dataInicio, dataFim);

    }



    return (

        <div className="container">
            <MounthSelect onChange={(mes, dia) => changeMes(mes, dia)} />
            <div className="shadow p-4 border rounded-resume">
                <h5>Boletos</h5>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Descricao</th>
                                <th>Histórico</th>
                                <th>Valor</th>
                                <th>Vencimento</th>
                                <th>Pago</th>
                            </tr>
                        </thead>
                        <tbody>
                            {boletos.map((item, index) => {
                                return (
                                    <tr key={index} className="p-4">
                                        <td>{item.centroDeCusto}</td>
                                        <td>{item.historico}</td>
                                        <td>{Utils.toCoin(item.valor)}</td>
                                        <td>{moment(item.dataVencimento).format("DD/MM/YYYY")}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>


        </div >
    )


}

export default TableBoletos;