import { useState, useEffect } from "react";

import Utils from "../../Services/Utils";

const CardBalanco = (props) => {

    const [boletos, setBoletos] = useState(props.boletos);

    const [sangrias, setSangrias] = useState(props.sangrias);

    const [suprimentos, setSuprimentos] = useState(props.suprimentos);

    const [totalBoletos, setTotalBoletos] = useState(0);

    const [totalSangrias, setTotalSangrias] = useState(0);

    const [totalSuprimentos, setTotalSuprimentos] = useState(0);

    const [totalBalanco, setTotalBalanco] = useState(0);

    const [totalBalancoHoje, setTotalBalancoHoje] = useState(0);

    useEffect(() => {

        let valorBoletos = 0;
        let valorBoletosHoje = 0;
        let valorSangria = 0;
        let valorSangriaHoje = 0;
        let valorSuprimento = 0;
        let valorSuprimentoHoje = 0;

        props.boletos.forEach(
            element => {

                if (Utils.isHoje(element.dataHora)) {
                    valorBoletosHoje += element.valor;
                }

                valorBoletos += element.valor;
            }
        )

        props.sangrias.forEach(
            element => {

                if (Utils.isHoje(element.dataHora)) {
                    valorSangriaHoje += element.valor;
                }

                valorSangria += element.valor;
            }
        )

        props.suprimentos.forEach(
            element => {

                if (Utils.isHoje(element.dataHora)) {
                    valorSuprimentoHoje += element.valor;
                }

                valorSuprimento += element.valor;
            }
        )

        setTotalBoletos(valorBoletos);

        setTotalSangrias(valorSangria);

        setTotalSuprimentos(valorSuprimento);

        setTotalBalanco(valorSuprimento - valorBoletos - valorSangria);

        setTotalBalancoHoje(valorSuprimentoHoje - valorBoletosHoje - valorSangriaHoje);

    }, [])

    return (

        <div>
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Balanco</h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(totalBalanco)}
                        </h2>
                        <small className="text-secondary">Hoje</small>
                        <h6 >
                            {Utils.toCoin(totalBalancoHoje)}
                            {' '}
                            {
                                totalBalancoHoje != 0 ?
                                    totalBalancoHoje > 0 ?
                                        <i style={{ fontSize: "1em" }} className="bi text-verde bi-arrow-up-circle-fill"></i>
                                        : <i style={{ fontSize: "1em" }} className="bi text-vermelho bi-arrow-down-circle-fill"></i>
                                    : null
                            }
                        </h6>

                    </div>
                    <div className="p-2 flex-fill">
                        {totalBalanco > 0 ?
                            <i style={{ fontSize: "4em" }} className="bi text-verde bi-arrow-up-circle-fill"></i>
                            : <i style={{ fontSize: "4em" }} className="bi text-vermelho bi-arrow-down-circle-fill"></i>
                        }
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardBalanco;