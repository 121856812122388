import { useState, useEffect } from "react";
import PollarisResumeApi from "../../Services/PollarisResumeApi";
import { Modal } from "react-bootstrap";
import ToastService from "../../Services/ToastService";

const PageCadastroDeSistemas = () => {

    const api = new PollarisResumeApi();

    const toast = new ToastService()

    const [sistemas, setSistemas] = useState([]);

    const [sistemaSelecionado, setSistemaSelecionado] = useState(null);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        //setSistemaSelecionado(sistemVazio());

        api.get("sistemas")

            .then(data => setSistemas(data))

            .catch(err => console.error(err));

    }, []);

    const editarSistema = (sistema) => {

        setSistemaSelecionado(sistema);

        setShowModal(true);

    }

    const sistemVazio = () => {

        return {

            nome: "",

            bancoDeDados: {

                tipo: 0,
                nome: "",
                connectionString: ""
            },
            cargas: []
        };
    }

    const salvarSistemaSelecionado = () => {

        api.post("sistemas", sistemaSelecionado).then(response => {
            
            setShowModal(false);

            toast.success("Sucesso", "Sistema salvo com sucesso");

        })

    }

    const adicionarCargaSistemaSelecionado = () => {

        let cargas = sistemaSelecionado.cargas;

        cargas.push({ endPointDestino: "", querie: "", filtroIntervalo: "" });

        setSistemaSelecionado({ ...sistemaSelecionado, cargas: cargas });

    }

    const handleNomeSistemaSelecionado = (e) => {

        setSistemaSelecionado({ ...sistemaSelecionado, nome: e.target.value });

    }

    const handleIntervaloParaCargaSistemaSelecionado = (e) => {

        setSistemaSelecionado({ ...sistemaSelecionado, intervaloParaCargas: e.target.value });

    }


    const handleConnectionStringSistemaSelecionado = (e) => {

        setSistemaSelecionado({ ...sistemaSelecionado, bancoDeDados: { ...sistemaSelecionado.bancoDeDados, connectionString: e.target.value } });

    }

    const handleNomeBancoSistemaSelecionado = (e) => {

        setSistemaSelecionado({ ...sistemaSelecionado, bancoDeDados: { ...sistemaSelecionado.bancoDeDados, nome: e.target.value } });

    }

    const handleTipoBancoSistemaSelecionado = (e) => {

        setSistemaSelecionado({ ...sistemaSelecionado, bancoDeDados: { ...sistemaSelecionado.bancoDeDados, tipo: e.target.value } });

    }

    const handleCargaEndPointSistemaSelecionado = (e, index) => {

        let cargas = sistemaSelecionado.cargas;

        cargas[index].endPointDestino = e.target.value;

        setSistemaSelecionado({ ...sistemaSelecionado, cargas: cargas });
    }

    const handleQuerieEndPointSistemaSelecionado = (e, index) => {

        let cargas = sistemaSelecionado.cargas;

        cargas[index].querie = e.target.value;

        setSistemaSelecionado({ ...sistemaSelecionado, cargas: cargas });
    }

    const handleFiltroIntervaloEndPointSistemaSelecionado = (e, index) => {

        let cargas = sistemaSelecionado.cargas;

        cargas[index].filtroIntervalo = e.target.value;

        setSistemaSelecionado({ ...sistemaSelecionado, cargas: cargas });
    }

    const handleSalvarSistema = () => {

    };

    return (
        <div className="container">
            <div className="shadow border p-4 rounded-resume">
                <button className="btn float-end btn-primary" onClick={() => editarSistema(sistemVazio())}>
                    <i className="bi bi-plus-circle"></i> Novo
                </button>
                <h4>Cadastro de Sistemas</h4>
                <hr />
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Banco</th>
                        </tr>
                    </thead>
                    <tbody>

                        {sistemas.map(sistema => (

                            <tr key={sistema.id}>
                                <td>{sistema.nome}</td>
                                <td>{sistema.bancoDeDados.nome}</td>
                                <td>
                                    <button className="btn btn-sm btn-primary" onClick={() => editarSistema(sistema)}>
                                        <i className="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal dialogClassName="modal-lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <div className="p-2">
                        {
                            sistemaSelecionado && sistemaSelecionado.id > 0
                                ? <h4>Editar Sistemas</h4>
                                : <h4>Novo Sistemas</h4>

                        }

                        <hr />
                        {
                            sistemaSelecionado &&
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Nome</label>
                                            <input value={sistemaSelecionado.nome} onChange={handleNomeSistemaSelecionado} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Intervalo em Minutos</label>
                                            <input value={sistemaSelecionado.intervaloParaCargas} onChange={handleIntervaloParaCargaSistemaSelecionado} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Banco de Dados</label>
                                            <select value={sistemaSelecionado.bancoDeDados.tipo} onChange={handleTipoBancoSistemaSelecionado} className="form-select">
                                                <option value='1'>MySQL</option>
                                                <option value='2'>SQL Server</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">ConnectionString</label>
                                        <input value={sistemaSelecionado.bancoDeDados.connectionString} onChange={handleConnectionStringSistemaSelecionado} type="text" className="form-control" />
                                    </div>
                                    <h5>Cargas</h5>
                                    {
                                        sistemaSelecionado.cargas.length > 0 &&

                                        <div className="accordion" id="accordionExample">
                                            {
                                                sistemaSelecionado.cargas.map((carga, index) => (

                                                    <div key={`carga_${index}`} className="accordion-item">
                                                        <h2 className="accordion-header" id={`header_carga_${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#body_carga_${index}`} aria-expanded="false" aria-controls="collapseOne">
                                                                {carga.endPointDestino}
                                                            </button>
                                                        </h2>
                                                        <div id={`body_carga_${index}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="mb-3">

                                                                    <label className="form-label">EndPoint</label>

                                                                    <input value={carga.endPointDestino} onChange={e => handleCargaEndPointSistemaSelecionado(e, index)} type="text" className="form-control" />

                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Querie</label>
                                                                    <textarea value={carga.querie} onChange={e => handleQuerieEndPointSistemaSelecionado(e, index)} className="form-control" rows="3"></textarea>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Filtro para Intervalo</label>
                                                                    <textarea value={carga.filtroIntervalo} onChange={e => handleFiltroIntervaloEndPointSistemaSelecionado(e, index)} className="form-control" rows="3"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                    }

                                    <div className="mb-3 mt-3">
                                        <button onClick={adicionarCargaSistemaSelecionado} className="p-3 rounded btn btn-outline-secondary col-12 text-center  border">
                                            <i className="bi bi-plus-circle" style={{ fontSize: '20px' }}></i> Adicionar Carga
                                        </button>
                                    </div>

                                    <div className="mb-3 mt-3">
                                        <button onClick={salvarSistemaSelecionado} className="btn btn-primary">Salvar</button>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PageCadastroDeSistemas;