import { useState, useEffect } from "react";

import moment from "moment/moment";

import PollarisResumeApi from "../../Services/PollarisResumeApi";
import Utils from "../../Services/Utils";
import PollarisResumeService from "../../Services/PollarisResumeService";

const CardSuprimentos = (props) => {

    const api = new PollarisResumeApi();

    const service = new PollarisResumeService();

    const sistemaSelecionado = service.getSistemaSelecionado();

    const [dataInicio, setDataInicio] = useState(props.dataInicio);

    const [dataFim, setDataFim] = useState(props.dataFim);

    const [totalDia, setTotalDia] = useState(0);

    const [totalMes, setTotalMes] = useState(0);

    const [loaded, setLoaded] = useState(false);

    const [boletos, setReceitas] = useState([]);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {

        if (loaded) return;

        setLoaded(true);

        loadBoletos();

    }, [])

    const loadBoletos = async () => {

        api.get(`movimentacao/${sistemaSelecionado}/suprimentos/${dataInicio}/${dataFim}`).then(data => {

            if (data) {

                var totalToday = 0;

                var totalMonth = 0;

                props.onLoad(data);

                data.forEach(element => {

                    if (moment(element.dataHora).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        totalToday += element.valor;
                    }

                    totalMonth += element.valor;
                });

                setReceitas(data);

                setTotalDia(totalToday);

                setTotalMes(totalMonth);
            }
        });

    }


    return (

        <div>
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Entradas</h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(totalMes)}
                        </h2>
                        <small className="text-secondary">Hoje</small>
                        <h6 className="text-verde" >{Utils.toCoin(totalDia)}</h6>
                    </div>
                    <div className="p-2 flex-fill">
                        <i style={{ fontSize: "4em" }} className="bi text-verde bi-arrow-up-circle"></i>
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardSuprimentos;