import { useState, useEffect } from "react";

import HomeChart from "../Components/HomeChart";
import TableBoletos from "../Components/Tables/TableBoletos";

export default params => {

    document.title = "Pollaris App | Boletos";

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        setLoaded(true);

    }, [])

    return (

        <div>
            <TableBoletos />
        </div>
    )

}