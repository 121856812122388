import moment from "moment/moment";

import { useState } from "react";
import Utils from "../../Services/Utils";



const MounthSelect = props => {

    const utils = new Utils();

    const [ano, setAno] = useState(moment().format('YYYY'));

    const [mes, setMes] = useState(moment().format('MM'));

    const nextMonth = () => {

        let novoMes = parseInt(mes) + 1;

        let novoAno = parseInt(ano);

        if (mes === '12') {

            novoMes = '01';
            novoAno = novoAno + 1;
        }

        setAno(`${novoAno}`);

        setMes(`${novoMes}`);

        props.onChange(`${novoMes}`, `${novoAno}`);
    }

    const prevMonth = () => {

        let novoMes = parseInt(mes) - 1;

        let novoAno = parseInt(ano);

        if (mes === '01') {

            novoMes = '12';
            novoAno = novoAno - 1;
        }

        setAno(`${novoAno}`);

        setMes(`${novoMes}`.padStart(2, '0'));

        props.onChange(`${novoMes}`, `${novoAno}`);

    }


    return (
        <div className="shadow p-2 mb-3 border rounded-resume">
            <div className="d-flex">
                <div className="p-2 flex-fill">
                    <button className="btn btn-lg" onClick={() => prevMonth()} ><i className="bi bi-arrow-left-circle"></i></button>
                </div>
                <div className="p-2 flex-fill">
                    <div className="text-secondary">
                        <small>{ano}</small>
                    </div>
                    <h4 className="mt-2">{utils.months[mes - 1]}</h4>
                </div>
                <div className="p-2">
                    <button className="btn btn-lg" onClick={() => nextMonth()} ><i className="bi bi-arrow-right-circle"></i></button>
                </div>
            </div>
        </div>
    )

}

export default MounthSelect;