import moment from "moment/moment";
import { useState } from "react";

import Utils from "../Services/Utils";
import CardBalanco from "./Charts/CardBalanco";
import CardBoletos from "./Charts/CardBoletos";

import CardSangrias from "./Charts/CardSangrias";
import CardSuprimentos from "./Charts/CardSuprimentos";
import MounthSelect from "./Screen/MouthSelect";

const HomeChart = params => {

    const [load, setLoad] = useState(true);

    const [showBalanco, setShowBalanco] = useState(false);

    const [boletos, setBoletos] = useState([]);

    const [sangrias, setSangrias] = useState([]);

    const [suprimentos, setSuprimentos] = useState([]);

    const [dataInicio, setDataInicio] = useState(moment().format(`YYYY-MM-01`));

    const [dataFim, setDataFim] = useState(moment().format(`YYYY-MM-${moment().daysInMonth()}`));

    const utils = new Utils();


    const changeMes = (mes, ano) => {

        setLoad(false);

        setDataInicio(`${ano}-${mes}-01`);

        setDataFim(`${ano}-${mes}-${moment(`${ano}-${mes}-01`).daysInMonth()}`);

        setTimeout(() => {

            setLoad(true);

        }, 500);

    }

    const refreshBalancoBoletos = (data) => {

        setShowBalanco(false);

        setBoletos(data);

        setTimeout(() => {
            setShowBalanco(true);
        }, 500)

    }

    const refreshBalancoSangrias = (data) => {

        setShowBalanco(false);

        setSangrias(data);

        setTimeout(() => {
            setShowBalanco(true);
        }, 500)

    }

    const refreshBalancoSuprimentos = (data) => {

        setShowBalanco(false);

        setSuprimentos(data);

        setTimeout(() => {
            setShowBalanco(true);
        }, 500)


    }


    return (

        <div className="container">

            <MounthSelect onChange={(mes, ano) => changeMes(mes, ano)} />

            {
                load ?
                    <div className="row">

                        <div className="col-md-4 mb-3">
                            <CardBoletos onLoad={(data) => refreshBalancoBoletos(data)} dataInicio={dataInicio} dataFim={dataFim} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CardSangrias onLoad={(data) => refreshBalancoSangrias(data)} dataInicio={dataInicio} dataFim={dataFim} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CardSuprimentos onLoad={(data) => refreshBalancoSuprimentos(data)} dataInicio={dataInicio} dataFim={dataFim} />
                        </div>
                        <div className="col-md-4 mb-3">

                            {showBalanco ?
                                <CardBalanco sangrias={sangrias} suprimentos={suprimentos} boletos={boletos} />
                                : null}
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default HomeChart;