import { useState, useEffect } from "react";

import moment from "moment/moment";

import Utils from "../../Services/Utils";

import PollarisResumeApi from "../../Services/PollarisResumeApi";
import { useNavigate } from "react-router-dom";
import PollarisResumeService from "../../Services/PollarisResumeService";

const CardBoletos = (props) => {

    const api = new PollarisResumeApi();

    const service = new PollarisResumeService()

    const sistemaSelecionado = service.getSistemaSelecionado();

    const navigate = useNavigate();

    const [dataInicio, setDataInicio] = useState(props.dataInicio);

    const [dataFim, setDataFim] = useState(props.dataFim);

    const [totalDia, setTotalDia] = useState(0);

    const [totalMes, setTotalMes] = useState(0);

    const [loaded, setLoaded] = useState(false);

    const [boletos, setBoletos] = useState([]);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {

        if (loaded) return;

        setLoaded(true);

        loadBoletos();

    }, [])

    const loadBoletos = async () => {

        api.get(`movimentacao/${sistemaSelecionado}/boletos/${dataInicio}/${dataFim}`).then(data => {

            if (data) {

                var totalToday = 0;

                var totalMonth = 0;

                props.onLoad(data);

                data.forEach(element => {

                    if (element.dataVencimento === moment().format('YYYY-MM-DD'))
                        totalToday += element.valor;

                    totalMonth += element.valor;
                });

                setBoletos(data);

                setTotalDia(totalToday);

                setTotalMes(totalMonth);

            }
        });

    }


    return (

        <div onClick={() => { navigate("/boletos") }}>
            <div className="shadow p-4 border rounded-resume">
                <div className="d-flex">
                    <div className="p-2 flex-fill">
                        <h5>Boletos</h5>
                        <h2 className="text-bold">
                            {Utils.toCoin(totalMes)}
                        </h2>
                        <small className="text-secondary">Pagar hoje</small>
                        <h6 >{Utils.toCoin(totalDia)}</h6>
                    </div>
                    <div className="p-2 flex-fill">
                        <i style={{ fontSize: "4em" }} className="bi bi-upc-scan"></i>
                    </div>
                </div>
            </div>


        </div >
    )


}

export default CardBoletos;