export default class Utils {

    constructor() {
        this.months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ]
    }

    static toCoin(value) {

        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    static isHoje(dataHora) {

        let data = new Date(dataHora);

        let hoje = new Date();

        return data.getDate() === hoje.getDate() && data.getMonth() === hoje.getMonth() && data.getFullYear() === hoje.getFullYear();
    }
}