import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import PollarisResumeService from '../Services/PollarisResumeService';

const NavBar = (props) => {

    const service = new PollarisResumeService();

    const [usuario, setUsuario] = useState(null);

    const [sistemas, setSistemas] = useState(service.getSistemasContratados());

    const [sistemaSelecionado, setSistemaSelecionado] = useState(service.getSistemaSelecionado());

    const navigate = useNavigate();

    useEffect(() => {

        setUsuario(JSON.parse(localStorage.getItem('authentication')));

    }, []);

    const logout = () => {

        localStorage.removeItem('authentication');

        window.location.href = '/';
    }

    const changeSistema = (sistema) => {

        service.setSistemaSelecionado(sistema);

        window.location.reload();
    }


    return (usuario &&
        <div>
            <nav className={`navbar navbar-expand-lg fixed-top bg-${sistemaSelecionado} shadow navbar-dark`}>
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img alt="Logo" width="45" height="30" src="/pollaris.png" ></img>
                    </Link>
                    <div className="text-light">
                        {usuario.customer.fantasia}
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <button className="nav-link dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                    {sistemaSelecionado}
                                </button>
                                <ul className="dropdown-menu">
                                    {
                                        sistemas && sistemaSelecionado.length > 0
                                            ? sistemas.map((sistema, index) => {
                                                return (

                                                    <li key={`select_sistema_${index}`}><a onClick={() => changeSistema(sistema)} className="dropdown-item" href="#">{sistema}</a></li>

                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <button className="nav-link dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                    Financeiro
                                </button>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/boletos">Boletos</Link></li>
                                </ul>
                            </li>
                        </ul>
                        {
                            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                                {
                                    usuario.customer.colaborador > 0 &&
                                    <li className="nav-item dropdown">
                                        <button className="nav-link dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                            Admin
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><button className="dropdown-item" onClick={() => navigate("/sistemas")}>Sistemas</button></li>
                                        </ul>
                                    </li>
                                }
                                <li className="nav-item dropdown">
                                    <button className="nav-link dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                        {usuario.customer.nome}
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><button className="dropdown-item text-danger" onClick={() => logout()}>Sair</button></li>
                                    </ul>
                                </li>

                            </ul>
                        }
                    </div>
                </div>
            </nav>
            <div style={{ marginBottom: "80px" }}></div>
        </div>
    )

}

export default NavBar;